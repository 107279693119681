import React from 'react';
import '../App.css';

function Homepage() {
  return (
    <div className="home-page">
      <h2>Welcome to our page!</h2>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, quidem?
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consectetur perferendis
        neque illum, officiaquisquam quasi voluptas debitis possimus assumenda! Ipsum reprehenderit
      </p>

      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, quidem?
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consectetur perferendis
        neque illum, officiaquisquam quasi voluptas debitis possimus assumenda! Ipsum reprehenderit
        maiores optio provident pariatur. Ipsum iure natus quisquam soluta!
      </p>
    </div>
  );
}

export default Homepage;
