import React from 'react';
import './NotFound.css';

function NotFound() {
  return (
    <div className="not-found">
      Oops Page Not Found!
    </div>
  );
}

export default NotFound;
