import React from 'react';
import Quote from '../components/Qoute/Quote';

function Qoutepage() {
  return (
    <div className="qoutes-page">
      <Quote />
    </div>
  );
}

export default Qoutepage;
